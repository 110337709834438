<template>
  <van-popup :close-on-click-overlay="false" v-model="visibility" class="dialog-alert">
    <div class="title">
      <img
        class="icon"
        v-if="title == '退款成功' || title == '解约成功'"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/suixintao/icon_success.png"
      />
      {{ title }}
    </div>
    <div class="cont">{{ message }}</div>
    <div class="footer">
      <div class="submit" @click="close">确定</div>
    </div>
  </van-popup>
</template>

<script>
export default {
  props: {
    visibility: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    message: { type: String, default: "提示语 " },
  },
  methods: {
    close() {
      this.$emit("update:visibility", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-alert {
  border-radius: 18px;
  width: 658px;
  padding-bottom: 42px;

  .title {
    font-weight: 600;
    font-size: 32px;
    color: #333333;
    line-height: 45px;
    text-align: center;
    padding: 40px 0 28px 0;
    line-height: 45px;

    .icon {
      width: 40px;
      padding-right: 12px;
    }
  }

  .cont {
    padding: 0 55px;
    font-weight: 400;
    font-size: 28px;
    color: #333333;
    line-height: 40px;
    max-height: 600px;
    overflow-y: auto;
    white-space: pre-wrap;
    text-align: center;
  }
  .footer {
    display: block;
    text-align: center;
    width: 480px;
    height: 76px;
    line-height: 76px;
    color: #fff;
    font-weight: 400;
    font-size: 32px;
    text-align: center;
    margin: 64px auto 0;
    background: linear-gradient(335deg, #ff7c00 0%, #ff5000 100%);
    border-radius: 44px;
  }
}
</style>
