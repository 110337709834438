<template>
  <div>
    <div class="refund" v-if="list.length != 0">
      <ul class="list" v-if="appName == 'huanxinxiang-zhengxing'">
        <template v-if="state == 'unsign'">
          <li class="list-item unsign" v-for="(item, index) in list" :key="index">
            <div class="top">
              <div>
                <img :src="setIcon(item.item_long_name)" class="icon" />
                <span class="name">{{ item.item_long_name }}</span>
              </div>
              <div class="money">¥{{ item.item_price }}/月</div>
            </div>
            <div class="bottom">
              <div class="">
                <div class="text">开通时间: {{ item.sign_time }}</div>
              </div>
              <div class="btn" @click="handleUnsign(item)">解约</div>
            </div>
          </li>
        </template>
        <template v-else>
          <li class="list-item" v-for="(item, index) in list" :key="index">
            <div class="top">
              <div>
                <img :src="setIcon(item.item_long_name)" class="icon" />
                <span class="name">{{ item.item_long_name }}</span>
              </div>
              <div class="money">¥{{ item.item_price }}</div>
            </div>
            <div class="bottom">
              <div class="">
                <div class="text">交易时间: {{ item.create_time }}</div>
                <div class="text">交易单号: {{ item.trade_no }}</div>
              </div>
              <div class="btn" @click="handleRefund(item)">退款</div>
            </div>
          </li>
        </template>
      </ul>
      <div class="refund-content" v-else>
        <div class="head">
          <img class="icon" :src="setIcon(current.item_long_name)" />
          <div class="title">{{ current.item_long_name }}</div>
          <div class="money">¥{{ current.item_price }}</div>
          <div class="type">
            {{ state === "refund" ? "申请退款" : state === "unsign" ? "申请解约" : "" }}
          </div>
        </div>
        <ul class="box">
          <li class="line">
            <div class="line-label">产品名称</div>
            <div class="line-value">{{ current.item_long_name }}</div>
          </li>
          <li class="line">
            <div class="line-label">资费说明</div>
            <div class="line-value">
              商户将每隔30天，自动从你的支付宝账户扣款{{
                current.item_price
              }}元进行续费，可随时取消，取消后不再自动续费
            </div>
          </li>
          <li class="border" />
        </ul>
        <div class="operate" @click="handleRefundOrUnsign" v-if="state === 'refund'">
          <div class="operate-label">我要退款</div>
          <img
            class="operate-icon"
            src="https://cdn.qiumo.net.cn/allsmarketmaterial/suixintao/icon_arrow.png"
          />
        </div>
        <div class="operate" @click="handleRefundOrUnsign" v-if="state === 'unsign'">
          <div class="operate-label">我要解约</div>
          <img
            class="operate-icon"
            src="https://cdn.qiumo.net.cn/allsmarketmaterial/suixintao/icon_arrow.png"
          />
        </div>
      </div>
    </div>

    <div class="blank" v-if="dialog">
      <div class="cont">
        <template v-if="operationalState === 'success'">
          <img
            class="icon"
            src="https://cdn.qiumo.net.cn/allsmarketmaterial/suixintao/refund_icon.png"
          />
          <div class="title">
            您已{{
              state == "refund" ? "退款并解约" : state == "unsign" ? "解约" : ""
            }}成功
          </div>
          <div class="msg" v-if="state == 'refund'">
            将于24小时内退还至原付款账户，请注意查收～
          </div>
          <div class="msg" v-if="state == 'unsign'">您已成功解约，希望与您再次相遇</div>
        </template>
        <template v-if="operationalState === 'fail'">
          <img
            class="icon"
            src="https://cdn.qiumo.net.cn/allsmarketmaterial/suixintao/refund_fail_icon.png"
          />
          <div class="title">
            {{ state == "refund" ? "退款" : state == "unsign" ? "解约" : "" }}失败
          </div>
          <div class="msg">
            {{ errorMsg }}
          </div>
        </template>
        <template v-if="!operationalState">
          <img
            class="icon"
            src="https://cdn.qiumo.net.cn/allsmarketmaterial/suixintao/refund_empty_icon.png"
          />
          <div class="empty-msg">您还没有相关的订单哦</div>
        </template>
        <div class="call" v-if="!!operationalState">
          客服电话：<u @click="handleCall(setCall)">{{ setCall }}</u>
        </div>
      </div>
    </div>
    <dialog-alert
      :visibility.sync="alert.visibility"
      :title="alert.title"
      :message="alert.message"
    />
  </div>
</template>

<script>
import {
  alipayLogin,
  alipayRefund,
  alipayUnsign,
  record,
  refundable,
  refundable30,
  refundsingle,
} from "@/api/refundsOrTerminationOfContracts";
const defaultData = { item_long_name: "", item_name: "", item_price: "" };

import DialogAlert from "./dialog/alert.vue";

export default {
  components: {
    DialogAlert,
  },
  data() {
    return {
      auth_code: "",
      state: "",
      appName: "",
      token: "",
      current: defaultData,
      dialog: true,
      // fail success
      operationalState: "",
      errorMsg: "",
      list: [],
      alert: {
        visibility: false,
        title: "",
        message: "",
      },
    };
  },
  computed: {
    setIcon() {
      return (name) => {
        console.log(name)
        let img = "";
        switch (name) {
          case "随心淘会员":
            img = "https://cdn.qiumo.net.cn/allsmarketmaterial/suixintao/icon_sxt.png";
            break;
          case "随心淘龙年专享":
            img =
              "https://cdn.qiumo.net.cn/allsmarketmaterial/suixintao/icon_sxtlnzx.png";
            break;
          case "惠生活会员":
            img = "https://cdn.qiumo.net.cn/allsmarketmaterial/suixintao/icon_hshhy.png";
            break;
          case "车主会员":
            img = "https://cdn.qiumo.net.cn/allsmarketmaterial/suixintao/icon_car.png";
            break;
          case "随心享会员":
            img = "https://cdn.qiumo.net.cn/allsmarketmaterial/suixintao/icon_sxxhy.png";
            break;
          case "美食会员":
            img = "https://cdn.qiumo.net.cn/allsmarketmaterial/suixintao/icon_food.png";
            break;
          case "车主畅享会员":
            img = "https://cdn.qiumo.net.cn/allsmarketmaterial/suixintao/icon_enjoy.png";
            break;
          default:
            break;
        }
        return img;
      };
    },
    setCall() {
      const call = {
        // 秋末
        huanxinxiang: "4001189599",
        // 海络
        "huanxinxiang-hailuo": "4001002501",
        // 铮行
        "huanxinxiang-zhengxing": "4001180238",
      };
      return call[this.appName];
    },
  },
  created() {
    const { auth_code = "", state = "", appName = "" } = this.$route.query;

    this.state = state;
    this.auth_code = auth_code;
    this.appName = appName;

    if (!auth_code) {
      const { origin, pathname } = location;
      const redirect_uri = encodeURIComponent(origin + pathname);
      const url = `https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?app_id=2021004138668016&scope=auth_base&redirect_uri=${redirect_uri}&state=${state}&appName=${appName}`;
      location.href = url;
    }

    if (!!auth_code) {
      this.login();
    }
  },
  methods: {
    async login() {
      const res = await alipayLogin({ auth_code: this.auth_code });
      this.token = res.token || "";
      if (!!this.token && this.state == "unsign") {
        this.getRecord();
      }

      if (!!this.token && this.state == "refund") {
        this.getRefundable();
      }
    },
    // 获取可解约列表
    async getRecord() {
      const res = await record(this.token, this.appName, {
        page: 1,
        data_type: "order",
        order_status: "订购中",
      });
      if (res.code == 0) {
        this.list = res.record;
        this.current = (res.record && res.record[0]) || defaultData;
        this.dialog = res.record.length === 0;
      }
    },
    //获取可退款列表
    async getRefundable() {
      const res =
        this.appName == "huanxinxiang-zhengxing"
          ? await refundable30(this.token, this.appName)
          : await refundable(this.token, this.appName);
      if (res.code == 0) {
        this.list = res.record;
        this.current = (res.record && res.record[0]) || defaultData;
        this.dialog = res.record.length === 0;
      }
    },
    // 列表单个退款
    async handleRefund(item) {
      const { trade_no = "" } = item;
      try {
        const { code = 0, message = "" } = await refundsingle(
          this.token,
          { trade_no },
          this.appName
        );
        this.alert = {
          visibility: true,
          title: code == 0 ? "退款成功" : "退款失败",
          message: message,
        };
        if (code == 0) {
          this.getRefundable();
        }
      } catch (error) {
        this.alert = {
          visibility: true,
          title: "退款失败",
          message: String(error),
        };
      }
    },
    async handleUnsign(item) {
      try {
        const { code = 0, message = "" } = await alipayUnsign(this.token, this.appName, {
          item_name: item.item_name,
        });
        this.alert = {
          visibility: true,
          title: code == 0 ? "解约成功" : "解约失败",
          message: code == 0 ? "" : message,
        };
        if (code == 0) {
          this.getRecord();
        }
      } catch (error) {
        this.alert = {
          visibility: true,
          title: "解约失败",
          message: String(error),
        };
      }
    },
    // 退款
    async handleRefundOrUnsign() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        mask: true,
        message: "加载中...",
      });
      try {
        const api =
          this.state == "refund"
            ? alipayRefund(this.token, this.appName)
            : this.state == "unsign"
            ? alipayUnsign(this.token, this.appName, {
                item_name: this.current.item_name,
              })
            : "";
        const res = await api;
        this.dialog = true;

        if (res.code == 0) {
          this.operationalState = "success";
        } else {
          this.operationalState = "fail";
          this.errorMsg = res.message;
        }
      } catch (error) {
        this.dialog = true;
        this.operationalState = "fail";
        this.errorMsg = "目前服务器异常，请稍后重试，可直接拨打客服电话，谢谢。";
      }
      this.$toast.clear();
    },
    handleCall(phoneNumber) {
      window.location.href = `tel:${phoneNumber}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.refund {
  background: #f5f5f5;
  padding: 30px 30px 30px 28px;
  min-height: 100vh;
  box-sizing: border-box;

  .list {
    &-item {
      background: #ffffff;
      box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.06);
      border-radius: 8px;
      padding: 22px 30px 30px;
      box-sizing: border-box;
      margin-bottom: 30px;

      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 19px;

        .icon {
          width: 72px;
          padding-right: 20px;
        }

        .name {
          font-weight: 400;
          font-size: 32px;
          color: #000000;
          line-height: 45px;
        }

        .money {
          line-height: 42px;
          font-size: 30px;
          color: #ff5000;
        }
      }

      .bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .text {
          font-weight: 400;
          font-size: 26px;
          color: #999999;
          line-height: 37px;
        }

        .btn {
          width: 126px;
          height: 62px;
          text-align: center;
          font-size: 26px;
          line-height: 62px;
          background: #ffffff;
          border-radius: 31px;
          color: #999999;
          box-sizing: border-box;
          border: 2px solid #e3e3e3;
        }
      }

      &.unsign {
        padding: 30px;

        .top {
          padding-bottom: 36px;
        }
      }
    }
  }

  &-content {
    background: #ffffff;
    border-radius: 18px;
    height: 94vh;

    .head {
      text-align: center;
      font-weight: 400;

      .icon {
        width: 72px;
        padding: 60px 0 35px;
      }

      .title {
        font-size: 30px;
      }

      .money {
        font-size: 38px;
        padding: 14px 0 10px;
      }

      .type {
        font-size: 28px;
      }
    }

    .box {
      padding: 60px 16px 0;

      .line {
        display: flex;
        font-weight: 400;
        padding-bottom: 59px;

        &:nth-child(2) {
          padding-bottom: 30px;
        }

        &-label {
          color: #888888;
          padding-right: 84px;
          font-size: 26px;
        }

        &-value {
          font-size: 26px;
          flex: 1;
        }
      }

      .border {
        width: 630px;
        margin: 0 auto;
        height: 1px;
        background-color: #e3e3e3;
      }
    }

    .operate {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 25px 16px 0;

      &-label {
        font-size: 26px;
        color: #000000;
      }

      &-icon {
        width: 44px;
      }
    }
  }
}

.blank {
  text-align: center;
  padding-top: 121px;

  .icon {
    width: 354px;
  }

  .title {
    font-size: 34px;
    padding: 40px 0 16px;
    color: #333333;
  }

  .msg {
    font-weight: 400;
    color: #999999;
    font-size: 28px;
    padding: 0 24px;
  }

  .empty-msg {
    font-size: 28px;
    color: #999999;
    padding-top: 44px;
  }

  .call {
    // position: fixed;
    // bottom: 102px;
    // left: 0;
    // right: 0;
    line-height: 34px;
    padding-top: 6px;

    font-weight: 400;
    font-size: 28px;
    color: #999999;

    u {
      color: #333333;
    }
  }
}
</style>
