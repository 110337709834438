import request from "@/utils/request";

// 登录
export const alipayLogin = (data) =>
  request({
    url: "/huanxinxiang-service/v1/alipay-h5-login/",
    method: "post",
    data,
  });

// 查询是否有可解约的数据
export const record = (Token, AppName, data) =>
  request({
    url: "/huanxinxiang-service/v1/get-record/",
    method: "post",
    headers: {
      Token: Token,
      "App-Name": AppName,
    },
    data,
  });

// 解约
export const alipayUnsign = (Token, AppName, data) =>
  request({
    url: "/huanxinxiang-service/v1/alipay-unsign/",
    method: "post",
    headers: {
      Token: Token,
      "App-Name": AppName,
    },
    data,
  });

// 退款
export const alipayRefund = (Token, AppName) =>
  request({
    url: "/huanxinxiang-service/v1/refund/",
    method: "post",
    headers: {
      Token: Token,
      "App-Name": AppName,
    },
  });

// 获取用户可退款订单
export const refundable = (Token, AppName) =>
  request({
    url: "/huanxinxiang-service/v1/get-refundable/",
    method: "post",
    headers: {
      Token: Token,
      "App-Name": AppName,
    },
  });

  
// 获取用户可退款订单-30天
export const refundable30 = (Token, AppName) =>
request({
  url: "/huanxinxiang-service/v1/get-refundable-30day/",
  method: "post",
  headers: {
    Token: Token,
    "App-Name": AppName,
  },
});

// 单笔退款
export const refundsingle = (Token, data, AppName) =>
request({
  url: "/huanxinxiang-service/v1/refund-single/",
  method: "post",
  headers: {
    Token: Token,
    "App-Name": AppName,
  },
  data,
});